import React from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';  
import defaultTheme from '../../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const StyledListWrapper = styled.div`
	padding: 20px;
	color: ${props => props.theme.mainTextColor};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 290px;
	background-color: #fff;

	.rc-list-title{
		margin-bottom: 12px;
	}
`;

const StyledList = styled.ul`
	width: 100%;

	li{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: nowrap;
		margin-bottom: 12px;

		&:last-child{
			margin-bottom: 0;
		}
		
		div:first-child{
			margin-right: 8px;
		}

		div:last-child:not(:first-child){
			justify-content: flex-end;
			text-align: right;
    		max-width: 50%;
		}
	}
`;

const StyledListItem = styled.div`
	font-weight: 500;
	line-height: 26px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	${props => {
		if(props.styles){
			for (let [key, value] of Object.entries(props.styles)) {
				return `${key}: ${value}`;
			}
		}
	}}

	.rc-list-bullet{
		font-size: 16px;
		color: ${props => props.theme.mainColor};
		margin-right: 8px;
	    align-self: flex-start;
	    padding-top: 4px;
	    margin-bottom: auto;
	    margin-top: -2px;

	    &.simple-bullet{
	    	font-size: 10px;
	    }
	}
`;

const renderItem = (listItem, index, t) => {
	if(Array.isArray(listItem)){
		return listItem.map((oneItem, index2) => {
			if(typeof oneItem === "string"){
				return <StyledListItem key={`item-${index}-${index2}`}>
					{index2 === 0 && <span className={classNames({'rc-list-bullet': true, 'simple-bullet': oneItem.options && typeof oneItem.options.icon === "undefined"})}>
					{
						oneItem.options && oneItem.options.icon && <FontAwesomeIcon icon={oneItem.options.icon || "circle"} />
					}
					</span>}
					{oneItem}
				</StyledListItem>
			} else {
				return <StyledListItem key={`item-${index}-${index2}`} {...oneItem.options}>
					{index2 === 0 && <span className={classNames({'rc-list-bullet': true, 'simple-bullet': oneItem.options && typeof oneItem.options.icon === "undefined"})}>
					{
						oneItem.options && oneItem.options.icon && <FontAwesomeIcon icon={oneItem.options.icon || "circle"} />
					}</span>}
					{typeof oneItem.value === "string" && oneItem.value}
					{typeof oneItem.value !== "string" && oneItem.value && t('yes')}
					{typeof oneItem.value !== "string" && !oneItem.value && t('no')}
				</StyledListItem>
			}
		});
	} else if(typeof listItem === "object"){
		return <StyledListItem {...listItem.options}>
			<span className={classNames({'rc-list-bullet': true, 'simple-bullet': listItem.options && typeof listItem.options.icon === "undefined"})}>
				{
					listItem.options && listItem.options.icon && <FontAwesomeIcon icon={listItem.options.icon || "circle"} />
				}</span>
			{listItem.value}
		</StyledListItem>
	}

	return <StyledListItem>
			<span className={classNames({'rc-list-bullet': true, 'simple-bullet': true})}><FontAwesomeIcon icon={"circle"} /></span>
			{listItem}
	</StyledListItem>;
}

const List = inject('chatStore')(
	observer(({list, title, ...rest}) => {
		const { t } = useTranslation();
		return <StyledListWrapper {...rest}>
			{
				title && <span className="rc-list-title">{t(title)}</span>
			}	
			<StyledList>	
			{
				list.map((listItem, index) => {
					return <li key={`item-${index}`}>{renderItem(listItem, index, t)}</li>
				})
			}
			</StyledList>
		</StyledListWrapper>
	})
);

List.defaultProps = {
	title: "titre de list",
	list: ["test1", ["Lorem ipsum dolor sit amet", {value: "OK", options: {styles: {color: "green"}}}], {value: "consectetur adipiscing elit.", options: {styles: {color: "red"}}}, "Aenean sodales tellus vel euismod accumsan"],
	theme: defaultTheme
};

export default List;