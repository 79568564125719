import React from 'react';
import { ThemeProvider } from 'styled-components';
import defaultTheme, {generateTheme} from './theme';
import Chat from './components/chat';
import './scss/App.scss';

import { Provider } from 'mobx-react';
import chatUiStore from './stores/chatUiStore';
import ChatStore from './stores/chatStore';
import WsConnector from './connectors/WsConnector';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';

import 'react-dates/initialize';

library.add(fas, fal);

let theme = {};
const pathArray = window.location.pathname.split('/');
const urlParams = new URLSearchParams(window.location.search);

let paramsList = [
	'backgroundColor',
	'mainColor',
	'mainTextColor',
	'botBubbleBgColor',
	'botBubbleTextColor',
	'userBubbleBgColor',
	'userBubbleTextColor',
	'headerBgColor',
	'headerTextColor',
];
for(var pair of urlParams.entries()) {
	if(paramsList.includes(pair[0])){
		theme[pair[0]] = `#${pair[1]}`;
	}
}

theme = generateTheme(theme);
theme = Object.assign(defaultTheme, theme);

const token = pathArray[pathArray.length - 1];
const userID = urlParams.get('userId');
let chatStore;

export default class App extends React.Component {

	state = {
		loading : true,
		connected: false
	}

	constructor(props){
		super(props);

		let params = (new URL(window.location)).searchParams;
		let contactInfos = {
			email: params.get("email"),
			firstName: params.get("firstName"),
			lastName: params.get("lastName"),
			phone: params.get("phone"),
		}
		Object.keys(contactInfos).forEach((key) => (contactInfos[key] == null) && delete contactInfos[key]);

		chatStore = new ChatStore(token, userID, contactInfos, chatUiStore);
		
		chatStore.theme = theme;
		chatStore.token = pathArray[pathArray.length - 1];
		chatStore.userId = urlParams.get('userId');

		const isRounded = urlParams.get('rounded') || false;
		chatUiStore.type = (isRounded === false || isRounded === "false") ? "default": "rounded";

		chatUiStore.avatarURL = urlParams.get('avatarURL') || null;

	}

	componentDidMount(){
		chatStore.setupEvents();
	}

	render(){

	  	return (
	   		<ThemeProvider theme={theme}>
				<Provider chatUiStore={chatUiStore} chatStore={chatStore}>
		      		<Chat loading={this.state.loading}/>
	      		</Provider>
	      	</ThemeProvider>
	  	);
	}
}
