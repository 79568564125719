const widgetsConfig = {
    "Bank.amount"           : {
        position : 'left',
        contained: true
    },
    "Generics.address"      : {
        position : 'left',
        contained: true
    },
    // "Generics.button"       : Generics.Button,
    "Generics.booleanbox"   : {
        position : 'left',
        contained: true
    },
    "Generics.datevisualizer"   : {
        position : 'left',
        contained: true,
        type: 'guess'
    },
    "Generics.datepicker"   : {
        position : 'right',
        contained: true
    },
    "Generics.dateselector" : {
        position : 'right'
    },
    // "Generics.download"     : Generics.Download,
    "Generics.downloadbox"  : {
        position : 'right',
        contained: true
    },
    "Generics.list"         : {
        position : 'left',
        contained: true
    },
    "Generics.summary"         : {
        position : 'center',
        contained: true
    },
    "Generics.image"       : {
        position : 'left',
        contained: true
    },
    "Generics.isDone"       : {
        position : 'full'
    },
    "Generics.quickreplies" : {
        position : 'right'
    },
    "Generics.quickrepliesmultiples" : {
        position : 'right'
    },
    "Generics.timepicker"   : {
        position : 'right',
        contained: true
    },
    "Generics.timeselector" : {
        position : 'right'
    },
    "Generics.upload"       : {
        position : 'right',
        contained: true
    }
};

export default widgetsConfig;