import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import {Bank, Generics} from './index';
import classNames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {StyledMessageBox, StyledMessage, StyledBubble, Avatar} from '../chatMessage';
import widgetsConfig from './WidgetsConfig';


const WidgetWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
    z-index: 3;

    justify-content: ${props => props.position === 'left' ? 'flex-start' : props.position === 'right' ? 'flex-end' : 'center'};
    text-align: ${props => props.position === 'full' ? 'center' : props.position};
    padding: ${props => props.position === 'full' ? '0 15px 0 15px' : '0 15px 0 57px'};
    width: auto;

    .widget-innner-wrapper{
        position: relative;
        display: inline-block;
        text-align: left;
        overflow: hidden;
        /*max-width: calc(100% - 10px);*/
        width: auto;

        box-shadow: ${props => {
            return (props.contained ? props.theme.shadow : "none");
        }};

        ${props => !props.contained ? "background: none !important" : ""};

        border-radius: ${props => props.contained ? props.position === "right" ? "20px 20px 4px 20px" : props.position === "left" ? "20px 20px 20px 4px" : "20px 20px 20px 20px" : "0px"};

        /*
            border: ${props => props.type === "guess" ? `1px dashed ${props.theme.mainColor}` : 'none'};
        */
    }
`;

const Widget = inject('chatUiStore')(
    observer(
        class Widget extends Component {
            widgetsList = {
                "Bank.amount"           : Bank.Amount,
                "Generics.address"      : Generics.Address,
                "Generics.button"       : Generics.Button,
                "Generics.booleanbox"   : Generics.BooleanBox,
                "Generics.datepicker"   : Generics.DatePicker,
                "Generics.dateselector" : Generics.DateSelector,
                "Generics.datevisualizer" : Generics.DateVisualizer,
                "Generics.download"     : Generics.Download,
                "Generics.downloadbox"  : Generics.DownloadBox,
                "Generics.list"         : Generics.List,
                "Generics.image"        : Generics.Image,
                "Generics.isDone"       : Generics.IsDone,
                "Generics.summary"      : Generics.Summary,
                "Generics.quickreplies" : Generics.QuickReplies,
                "Generics.quickrepliesmultiples" : Generics.QuickRepliesMultiples,
                "Generics.timepicker"   : Generics.TimePicker,
                "Generics.timeselector" : Generics.TimeSelector,
                "Generics.upload"       : Generics.Upload
            };

            render() {
            	const { tag, chatUiStore, ...rest } = this.props;
            	if( tag === null || typeof this.widgetsList[tag] === "undefined") return null;

               	const TagName = this.widgetsList[tag];
                const position = widgetsConfig[tag].position || 'center';
                const contained = widgetsConfig[tag].contained || false;
                const type = widgetsConfig[tag].type || 'normal';

                return <WidgetWrapper position={position} contained={contained} type={type}>
                    <div className="widget-innner-wrapper">
                        <TagName {...rest} />
                    </div>
                </WidgetWrapper>

                // return <StyledMessageBox className={classNames("ChatBot__Message", `ChatBot__Message_${from}`)} from={from} displayPlayer={false} rounded={true} loading={"false"} >
                //     <div className="content">
                //         <StyledMessage withBotOffset={(from === "bot" || from === "member") && chatUiStore.showBotAvatar}>
                //             <TransitionGroup>
                //                 <CSSTransition classNames="BubbleTransition" timeout={500} appear={true}>
                //                     <div style={{width: "100%"}}>
                //                         <StyledBubble showBubble={showBubble} width="auto">
                //                             <TagName {...rest}/>
                //                         </StyledBubble>
                //                     </div>
                //                 </CSSTransition>
                //             </TransitionGroup>
                //         </StyledMessage>
                //     </div>
                // </StyledMessageBox>;
               	//return <TagName {...rest}/>
            }
        }
    )
);
export default Widget;
