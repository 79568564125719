import React from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import moment from 'moment';     
import defaultTheme from '../../../theme';

export const StyledAmount = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
	background: ${props => props.amount > 0 ? "linear-gradient(to right top, #11cb72, #22d27d, #2fd987, #3ae192, #44e89c)" : "linear-gradient(to right top, #ff5451, #ff6a60, #ff7d6f, #ff8f7f, #fea090)"};

	color: #fff;
	padding: 20px;

	box-shadow: ${props => props.theme.shadow};

	.Rc_Amount__account_label{
	}

	.Rc_Amount__date{
		font-size: 12px;
	}

	.Rc_Amount__amount{
		font-size: 38px;
		font-weight: 600;
		text-align: center;
	}
`;

const Amount = inject('chatStore')(
	observer((props) => {
		return <StyledAmount {...props}>
			<span className="Rc_Amount__date">{ moment(props.date).calendar() }</span>
			<span className="Rc_Amount__account_label">{ props.accountLabel }</span>
			<div className="Rc_Amount__amount" >{ props.amount } { props.currency }</div>
		</StyledAmount>
	})
);

Amount.defaultProps = {
	accountLabel: "",
	amount: 0,
	date: new Date(),
	currency: "$",
	theme: defaultTheme
};
export default Amount;