import React from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';  
import defaultTheme from '../../../theme';
import DropzoneComponent from 'react-dropzone-component';

import { Message } from '../../../stores/chatStore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/pro-light-svg-icons';

import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';
var ReactDOMServer = require('react-dom/server');
var mime = require('mime-types')

const getColorByExtension = (ext) => {
	let color = {
		background: "rgba(46, 67, 231, 0.8)",
		text: "#fff"
	};
	switch(ext){
		case "png":
		case "gif":
		case "jpg":
		case "jpeg":
			color = {
				background: "rgba(17, 203, 114, 0.8)",
				text: "#fff"
			}
			break;
		case "pdf":
		case "doc":
		case "docx":
			color = {
				background: "rgba(255, 84, 81, 0.8)",
				text: "#fff"
			}
			break;
		default:
	}


	return color; 
};

const Upload = inject('chatUiStore' ,'chatStore')(
	observer(
		class Upload extends React.Component {

			constructor(props) {
		        super(props);

		        this.djsConfig = {
		            addRemoveLinks: false,
		            // acceptedFiles: "image/jpeg,image/png,image/gif",
		            previewTemplate: ReactDOMServer.renderToStaticMarkup(
					    <div className="dz-preview">
					    	<div className="response-container">
								<div className="dz-success-mark"></div>
								<div className="dz-error-mark"></div>
							</div>

							<div className="dz-error-message"><span data-dz-errormessage="true"></span></div>

							<div className="dz-details">
								<div className="dz-details-text">
									<div className="dz-image-wrapper">
										<img data-dz-thumbnail="true" alt="upload-thumbnail"/>
										<div className="overlay"></div>
									</div>
									<div className="dz-filename">
										<span data-dz-name="true"></span>
									</div>
									<div className="dz-size" data-dz-size></div>
								</div>
								<div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
							</div>
					    </div>
				  	),
					previewsContainer: ".dropzone-previews"
		        };

		        this.componentConfig = {
		            showFiletypeIcon: false,
		            dropzoneSelector: ".dropzone-custom",
		            postUrl: '/uploadHandler'
		        };

		        this.callback = (e) => {
		        	let extension = mime.extension(e.type);
		        	let eltWrapper = e.previewElement.querySelector(".dz-image-wrapper");
		        	let eltOverlay = eltWrapper.querySelector(".overlay");
					let colorElt = getColorByExtension(extension);

		        	eltOverlay.dataset.extension = extension;
					eltWrapper.querySelector(".overlay").style.backgroundColor = colorElt.background;
					eltWrapper.querySelector(".overlay").style.color = colorElt.text;
		        };

		        this.success = file => {
		        	console.log("success");
	        	}

	        	this.queuecomplete = e => {
					const { chatStore } = props;

					chatStore.addMessage(new Message({message: "Files Uploaded", from: "bot", loading: false}));
	                chatStore.step = chatStore.step + 1;
	        	}

		        this.removedfile = file => console.log('removing...', file);

		        this.dropzone = null;
		    }

		    render() {
		        const config = this.componentConfig;
		        const djsConfig = this.djsConfig;

		        const eventHandlers = {
		            init: dz => this.dropzone = dz,
		            addedfile: this.callback,
		            success: this.success,
		            queuecomplete: this.queuecomplete,
		            removedfile: this.removedfile
		        }

		        return <StyledUpload {...this.props}>
	        		<div className='dropzone dropzone-previews'>
	        		</div>
	        		<div className="dropzone dropzone-custom">
	        			<div className="dz-default dz-message">
							<FontAwesomeIcon icon={faFileUpload} />
							<span>{"Drag and drop files here or "}<strong>{"browse"}</strong></span>
	        			</div>
	        		</div>
		        	<DropzoneComponent config={config} eventHandlers={eventHandlers} djsConfig={djsConfig} />
				</StyledUpload>
		    }
		}
	)
);

Upload.defaultProps = {
	theme: defaultTheme
};

export const StyledUpload = styled.div`

	.filepicker{
		display: none;
	}

	.dropzone.dz-drag-hover{
		border: none;
	}

	.dropzone-custom{
		display: flex;
		flex-direction: column;
		padding: 0;
		min-height: auto;

		background-color: #FFF;
		color: #8292a5; 
		border: none;
		border-radius: 0;
		box-shadow: ${props => props.theme.shadow};

		&.dz-started .dz-message{
			display: flex;
		}
	
		.dz-message{
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			height: auto;
			margin: 0 12px;
			color: #8292a5;
			background: #fff; 
			cursor: pointer;
			z-index: 25;
			padding-bottom: 20px;
			padding-top: 20px;

			strong {
				color: ${props => props.theme.mainColor};
			}

			svg{
				font-size: 35px;
				margin-right: 12px;
				color: ${props => props.theme.mainColor};
			}
		}
	}

	.dropzone-custom-btn{
		.dz-message{
			margin-top: 12px;
			margin-left: auto;
			margin-right: auto;
			width: auto;
			height: auto;
			border-radius: 12px;
			padding: 12px 30px;
		}
	}

	.dropzone-previews{
	   	position: relative;
    	overflow: hidden;
	    position: relative;
	    overflow-x: hidden;
	    overflow-y: scroll;
	    border: none;
	    padding: 0;

	    min-height: 0;
	    max-height: 264px;


		.dz-preview{

			position: relative;
			display: flex;
			background: #fff;
			padding: 8px 20px;
			margin: 0px;
			min-height: 0;
    		flex-shrink: 0;
    		overflow: hidden;

			&:after{
				content: "";
				position: absolute;
				bottom: 0;
				left: 10%;
				right: 10%;
				height: 1px;
				background-image: linear-gradient(to right, #f9f9fa, #f6f6f8, #f2f3f6, #eff1f4, #ebeef2, #ebeef2, #ebeef2, #ebeef2, #eff1f4, #f2f3f6, #f6f6f8, #f9f9fa);
			}

			&.dz-image-preview{
				position: relative;
				background:  #FFF;
				overflow: hidden;
			}

			.dz-image-wrapper{
				position: relative;
				margin: 0px;
				border-radius: 8px;
				width: 40px;
				height: 40px;
				overflow: hidden;
				flex-shrink: 0;

				.overlay{
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;

					&:before{
						content: attr(data-extension);
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;

						display: flex;
						align-items: center;
						justify-content: center;
						color: inherit;

						text-transform: uppercase;
						font-size: 11px;
					}
				}
			}
			
			.dz-progress{
				position: relative;
			    height: 6px;
			    width: 100%;
			    background-color: #e5eaef;
			    border-radius: 3px;
			    opacity: 1;
			    top: auto;
			    left: auto;
			    margin-top: 10px;
			    margin-left: 20px;
			    margin-right: 20px;
				box-shadow: 0 3px 6px 0px rgba(32,45,113,.05);

			    .dz-upload{
			   		background: #6b79ff;
			    }
			}


			.response-container{

			}
	
			.dz-details{
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				opacity: 1;
				position: relative;
				padding: 0;
				line-height: normal;
				top: auto;
				left: auto;
				text-align: left;
				font-size: 13px;
				width: 100%;

				.dz-details-text{
				    display: flex;
				    align-items: center;
				    justify-content: space-between;
				    width: 100%;
				    margin-top: 16px;
				}
	
				.dz-filename, .dz-size{
					color: #8292a5;
				}

				.dz-filename{
				    margin-left: 0;
				    margin-right: auto;

				    &:hover span{
				    	border: none;
				    	background-color: inherits;
				    }
				}

				.dz-size{
					min-width: 60px;
					margin-bottom: 0;
					color: #bfcad8;
					font-size: 10px;
					text-align: right;
				}

				img{
					width: 100%;
					height: 100%;
    				object-fit: contain;
				}
			}


			&.dz-success .dz-success-mark, &.dz-error .dz-error-mark{	
			    animation: none;
			    opacity: 1;
			}

			.dz-success-mark, .dz-error-mark{
			    display: flex;
			    align-items: center;
			    justify-content: center;

				width: 6px;
			    top: 0;
			    left: 0;
			    bottom: 0;
			    margin: 0;
			    font-size: 12px;
			    margin: 0;
			}

			.dz-error-mark{
				background-color: #ff5451;
			}

			.dz-success-mark{
				background-color: #11cb72;
			}

			.dz-error-message{
				display: none;
			}
		}
	}
`;


export default Upload;