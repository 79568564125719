import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common_fr from './Translations/fr/common.json';
import common_en from './Translations/en/common.json';

const resources = {
	fr: common_fr,
	en: common_en
};
i18n.use(initReactI18next).init({
	resources,
	lng: 'fr',
	keySeparator: false,
	interpolation: {
		escapeValue: false
	}
});

export default i18n;
