import React, { Fragment } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';   
import defaultTheme from '../../../theme';
import TimePicker from './TimePicker';
import { DayPickerSingleDateController } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import 'react-dates/lib/css/_datepicker.css';

class DateRangePickerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: true,
      date: props.initialDate,
      hours: 0,
      minutes: 0
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onDateChange(date) {
    this.setState({ date });  	
	if(!this.props.showTime && date){
		this.props.onDatesChange(date);
	}
  }

  onFocusChange() {
    // Force the focused states to always be truthy so that date is always selectable
    this.setState({ focused: true }); 
  }

  onTimeChange(hours, minutes) {
    this.setState({ hours, minutes });
  }

  getDateWithTime(){
  	const { date } = this.state;
	if(this.props.showTime && date){
  		date.minutes(this.state.minutes);
  		date.hours(this.state.hours);
  	}

  	return date;
  }

  onSubmit(){
	let currentDate = this.getDateWithTime();
	if(currentDate){
		this.props.onDatesChange(currentDate);
	}
  }

  render() {
    const { focused, date } = this.state;
	const rest = {...this.props};
	const showTime = rest.showTime;
	delete rest["autoFocus"];
	delete rest["onDatesChange"];
	delete rest["initialDate"];
	delete rest["showTime"];

    return <Fragment>
	    <DayPickerSingleDateController
	      {...rest}
	      onDateChange={this.onDateChange}
	      onFocusChange={this.onFocusChange}
	      focused={focused}
	      date={date}
	      withPortal={true}
	    />
	    {
	    	showTime && <Fragment>
		    	<div className="time-picker">
		    		<TimePicker 
		    			onChange={this.onTimeChange}
		    			onSubmit={this.onSubmit}
	    			/>
		    	</div>
	    	</Fragment>
	    }
    </Fragment>
  }
};

export const StyledDatePicker = styled.div`
 	width: 100%;
   	min-height: ${props => props.showTime ? "464px" : "346px" };
   	min-width: 300px;
	
	& > div:first-child{
		top: 0;
		bottom: 0;
		margin-top: 0 !important;
	}

	

	.DayPickerNavigation_button__default{
		border: none;
	}

	.DayPicker_weekHeader{
		top: 56px;
	}

	.DayPicker_weekHeader_li {
	  visibility: hidden;
	  font-size: 0;
	}

	.DayPicker_weekHeader_li::first-letter {
	  visibility: visible;
	  font-size: 18px;
	}

	.CalendarDay__default{
		position: relative;
		border: none;
    	vertical-align: middle;
    	z-index: 1;
		color: ${props => props.theme.mainTextColor};

		&:hover{
			border: none;
			background: ${props => props.theme.mainColor};
			border-color: ${props => props.theme.mainColor};
			color: #fff;
		}
	}

	.CalendarDay__selected_span {
		background: ${props => props.theme.mainColor};
	}

	.CalendarDay__selected {
		position: relative;
		background: ${props => props.theme.mainColor};
		color: #fff;
		z-index: 0;

		&:before{
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: ${props => props.theme.mainColor};
			border-radius: 6px;
			z-index: -1;
		}
	}

	.CalendarDay__selected_start{
		border-radius: 6px 0 0 6px;
	}

	.CalendarDay__selected_end{
		border-radius: 0 6px 6px 0;
	}

	.CalendarDay__hovered_span:hover,
	.CalendarDay__hovered_span {
		background: ${props => props.theme.mainColor};
		color: $reecall-black;
	}

	.CalendarMonth_caption{
		font-size: 16px;
		line-height: 22px;
		font-weight: 600;
		padding-bottom: 57px;
	}

	.DayPicker_weekHeader_li{
		text-transform: uppercase;
		color: #444e5a;
	}

	.DayPickerNavigation_button__horizontal{
	    display: flex;
	    flex-direction: row;
		position: absolute;
		top: 18px;
    	padding: 5px;

		&:first-child{
			left: 25px;
		}

		&:last-child{
			right: 25px;
		}
	}

	.DayPickerNavigation_button__horizontalCustom{
	    position: relative;
		width: 20px;
		height: 20px;
		color: ${props => props.theme.mainColor};
	
		&.DayPickerNavigation_left{
		    margin-right: auto;
		    margin-left: 0px;
		} 

		&.DayPickerNavigation_right{
		    margin-left: auto;
		    margin-right: 0px;
		}
	}

	.time-picker{
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0);
		width: 100%;

		&:after{
			content: "";
			position: absolute;
			top: 0;
			left: 10%;
			right: 10%;
			height: 1px;
			background-image: linear-gradient(to right, #f9f9fa, #f6f6f8, #f2f3f6, #eff1f4, #ebeef2, #ebeef2, #ebeef2, #ebeef2, #eff1f4, #f2f3f6, #f6f6f8, #f9f9fa);
		}

		& > div:first-child{
			width: 100%;
			padding: 8px 0 0;
		}
	}
`;

const DatePicker = inject('chatStore')(
	observer(({initialDate, onDatesChange, showTime, ...rest}) => {

		return <StyledDatePicker showTime={showTime} {...rest} >
			<DateRangePickerWrapper
				autoFocus={false}
				onDatesChange={onDatesChange}
			  	enableOutsideDays={false}
				initialDate={initialDate}
				initialVisibleMonth={null}
				isRTL={false}
				keepOpenOnDateSelect={false}
				monthFormat="MMMM YYYY"
				weekDayFormat="dd"
				navPrev={<FontAwesomeIcon icon={faChevronLeft} className="DayPickerNavigation_button__horizontalCustom DayPickerNavigation_left"/>}
				navNext={<FontAwesomeIcon icon={faChevronRight} className="DayPickerNavigation_button__horizontalCustom DayPickerNavigation_right"/>}
				numberOfMonths={1}
				orientation="horizontal"
				renderCalendarDay={undefined}
				renderCalendarInfo={null}
				renderDayContents={null}
				hideKeyboardShortcutsPanel={true}
				showTime={showTime}
			/>
		</StyledDatePicker>
	})
);

DatePicker.defaultProps = {
	initialDate: null,
	onDatesChange: (e) => { },
	theme: defaultTheme,
	showTime: false
};

export default DatePicker;