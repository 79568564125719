import { lighten } from 'polished'

export default {
  backgroundColor: '#fff',
  fontFamily: "'Inter UI', sans-serif",
  mainColor: "#475cff",
  mainTextColor: "#8292a5",
  shadow: "0 2px 6px 2px rgba(32,45,113,.1)",

  botBubbleBgColor: '#fff',
  botBubbleTextColor: '#8292a5',

  userBubbleBgColor: '#475cff',
  userBubbleTextColor: '#fff',

  inputTextColor: '#8292a5',
  inputBgColor: '#fff',
  inputIconColor: '#475cff',
  inputIconBgColor: '#eceeff',
  inputShadow: "0 4px 20px rgba(32,45,113,.1)",

  headerBgColor: '#475cff',
  headerTextColor: '#fff',

  hintBgColor: 'linear-gradient(to right top, #6b79ff, #6372ff, #5a6bff, #5163ff, #475cff)',
  hintTextColor: '#fff',
  hintBorderColor: '#6372ff',
  hintShadow: "0 4px 8px 4px rgba(32,45,113,.14)",

  timeBgColor: "#eceeff",
  timeBorderColor: "transparent",
  timeTextColor: "#2e43e7",
};

export const generateTheme = (theme) => {
  if(theme.mainColor){
    theme.mainLightenColor          = lighten(0.3, theme.mainColor);
    theme.userBubbleBgColor         = theme.userBubbleBgColor || theme.mainColor;
    theme.inputIconColor            = theme.inputIconColor || theme.mainColor;
    theme.inputIconBgColor          = theme.inputIconBgColor || theme.mainLightenColor;
    theme.headerBgColor             = theme.headerBgColor || theme.mainColor;
    
    theme.hintBgColor               = theme.hintBgColor || theme.mainColor;
    theme.hintBorderColor           = theme.hintBorderColor || theme.mainColor;

    theme.timeBgColor               = lighten(0.4, theme.mainColor);
    theme.timeTextColor             = theme.timeTextColor || theme.mainColor;
  }
  
  return theme;
}