import moment from 'moment';

moment.locale('tiny_en', {
    calendar: {
         lastDay : '[Yesterday]',
	    sameDay : '[Today]',
	    nextDay : '[Tomorrow]',
	    lastWeek : '[last] dddd',
	    nextWeek : 'dddd',
	    sameElse : 'L'
    }
});