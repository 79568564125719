import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';  
import defaultTheme from '../../../theme';
import Button from './Button';

const StyledTimePickerWrapper = styled.div`
	margin-left: auto;
	margin-right: auto;
    width: 100%;
    max-width: 319px;
    background-color: #fff;
`;

const StyledTimeWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px 20px 20px;
    color: ${props => props.theme.userBubbleBgColor};


	.time-separator{
		padding-top: 22px;
	}
`;

const StyledTime = styled.div`

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	label{
		text-align: center;
		color: #8292a5;
		font-size: 14px;
		font-weight: normal;
    	user-select: none;
	}

	
	& > div{
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0 12px;
	}

	input{
		-webkit-appearance: none;
	    border: 0;
	    box-shadow: none;
	    text-align: center;
	    width: 100%;
	    padding: 12px;
	    color: ${props => props.theme.userBubbleTextColor};
	    background: ${props => props.theme.userBubbleBgColor};
	    font-weight: 600;
	    border-radius: 6px;
	    font-size: 14px;
	    line-height: 16px;
    	user-select: none;
	}

	.up, .down{
		position: relative;
		height: 16px;
    	user-select: none;

		span{
			cursor: pointer;
			position: absolute;
    		user-select: none;
		}
	}

	.up{
		span{
			top : 0;
			left: 50%;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 7px 10px 7px;
			border-color: transparent transparent ${props => props.theme.userBubbleBgColor} transparent;
			transform: translate(-50%, 0);
		}	
	}

	.down{
		span {
			bottom : 0;
			left: 50%;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 7px 0 7px;
			border-color: ${props => props.theme.userBubbleBgColor} transparent transparent transparent;
			transform: translate(-50%, 0);
		}
	}
`;

	
const StyledFooterButtons = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	.rc-button{
		width: 100%;
		margin: 0;
		padding: 6px;
		border-radius: 0;
		text-align: center;
	}
`;

const TimePicker = inject('chatStore')(
	observer((props) => {
		const isInitialMount = useRef(true);
		const [hours, setHours] = useState(0);
		const [minutes, setMinutes] = useState(0);

		useEffect(() => {
			if (isInitialMount.current) {
	     		isInitialMount.current = false;
		  	} else {
				props.onChange(hours, minutes);
			}
		});

		return <StyledTimePickerWrapper>
			<StyledTimeWrapper>
				<StyledTime>
					<label>Hours</label>
					<div>
						<span 
							className="up"
							onClick={() => {
								if(hours + props.hourInterval < 24) {
									setHours(hours + props.hourInterval)
								}
							}}
						>
							<span></span>
						</span>
		  				<input 
		  					name="hour"
		  					step={props.hourInterval}
		  					min={0}
		  					max={24 - props.hourInterval}
		  					value={hours}
		  					onChange={(e) => {setHours(e.currentTarget.value)}}
						/>
						<span 
							className="down"
							onClick={() => {
								if(hours + props.hourInterval > props.hourInterval) {
									setHours(hours - props.hourInterval)
								}
							}}
						>
							<span></span>
						</span>
					</div>
				</StyledTime>
				<span className="time-separator">:</span>
				<StyledTime>
					<label>Minutes</label>
					<div>
						<span 
							className="up"
							onClick={() => {
								if(minutes + props.minuteInterval < 60) {
									setMinutes(minutes + props.minuteInterval)
								}
							}}
						>
							<span></span>
						</span>
		  				<input
			  				name="minutes"
			  				step={props.minuteInterval}
			  				min={0}
			  				max={60 - props.minuteInterval}
			  				value={minutes}
							onChange={(e) => {setMinutes(e.currentTarget.value)}}
						/>
						<span 
							className="down"
							onClick={() => {
								if(minutes - props.minuteInterval > props.minuteInterval) {
									setMinutes(minutes - props.minuteInterval)
								}
							}}
						>
							<span></span>
						</span>
					</div>
				</StyledTime>

			</StyledTimeWrapper>

			<StyledFooterButtons>
		    	<Button
	                backgroundColor={props.theme.userBubbleBgColor}
	                textColor={props.theme.userBubbleTextColor}
	                onClick={() => props.onSubmit(hours, minutes)}
	            >
		    		sousmettre
		    	</Button>
			</StyledFooterButtons>
		</StyledTimePickerWrapper>
	})
);

TimePicker.defaultProps = {
	hourInterval: 1,
	minuteInterval: 5,
	value: null,
	onChange: (hours, minutes) => {console.log(hours, minutes, "e on Change time")},
	onSubmit: (hours, minutes) => {},
	theme: defaultTheme
};

export default TimePicker;