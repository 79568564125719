const axios = require('axios');
const error = message => error => {
    const err = new Error(message);
    err.error = error;
    throw err;
}

class DefaultConnector {
    /**
     * Initializes the SDK
     * @param {string} token - the token to use for the API
     */

    constructor() {
        // this.API_BASE = (window.location.hostname === 'localhost')
        //     ? 'https://localhost:8088'
        //     : 'https://connector-generic.reecall.cloud';

        this.API_BASE = 'https://connectors.reecall.co/chat';
        let urlParams = new URLSearchParams(window.location.search.slice(1))

        if (urlParams.has('debugUrl')) {
            this.API_BASE = urlParams.get('debugUrl');
        }

        //this._checkApiUrl();
    }

    _checkApiUrl(){
        axios.get(this.API_BASE+'/').then(() => {
            // console.info("Connector respond");
        }).catch((err) => {
          console.warn(`WARNING: The api url (${this.API_BASE}) seems incorrect. Please check the variable API_URL.`);
          this.axios = null;
        });
    }

    connect(identifier, token){
        return axios.post(this.API_BASE+'/connect', {
           'userid': identifier,
           'token': token
        })
        .then((results) => {
            return results.data;
        })
        .catch(error('Error on connect - generic connector'));
    }


    say(identifier, sentence){
        return axios.post(this.API_BASE+'/say', {
           'userid': identifier,
           'message': sentence
        })
        .then((results) => {
            return results.data;
        })
        .catch(error('Error on say - generic connector'));
    }

}

export default DefaultConnector;
