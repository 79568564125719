import React from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react'; 

export const ImageWrapper = styled.div`
    display: flex;
	background-color: #fff;
	img{
		max-width: 100%;
		max-height: 400px;
	}
`;

const Image = ({src}) => <ImageWrapper>
	<img src={src} />	
</ImageWrapper> 

Image.defaultProps = {
	src: null
};

export default Image;