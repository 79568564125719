import React from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';  
import defaultTheme from '../../../theme';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/pro-light-svg-icons';

export const StyledBooleanBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 20px;
	background-color: #fff;

	.rc-boolean-content{
		flex-grow: 1;
		color: ${props => props.value ? "#11cb72" : "#ff5451" };
	}
	
	.rc-boolean-icon{
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 30px;
		color: ${props => props.value ? "#11cb72" : "#ff5451" };
		border: 1px solid;
		border-color: ${props => props.value ? "#11cb72" : "#ff5451" };
		border-radius: 50%;
		width: 40px;
		height: 40px;
		margin-right: 12px;
	}

`;

const BooleanBox = inject('chatStore')(
	observer((props) => {

		return <StyledBooleanBox {...props}>
			<div className="rc-boolean-icon">
				{ props.value && <FontAwesomeIcon icon={faCheck} /> }
				{ !props.value && <FontAwesomeIcon icon={faTimes} /> }
			</div>
			<div className="rc-boolean-content">
				{
					props.children
				}
			</div>
		</StyledBooleanBox>
	})
);

BooleanBox.defaultProps = {
	value: false,
	theme: defaultTheme
};

export default BooleanBox;