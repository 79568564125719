import React from 'react';
import { observer, inject } from 'mobx-react';  
import defaultTheme from '../../../theme';
import styled from 'styled-components';

const StyledIsDone = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
    max-width: 100%;

	&:before{
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		height: 1px;
		background-color: ${props => props.theme.userBubbleBgColor};
	}

	span{
		background-color: #fff;
		padding: 4px 12px;
		z-index: 1;
    	max-width: 70%;
    	text-align: center;
	}
`;

const IsDone = inject('chatStore')(
	observer(({text, ...rest}) => {
		return <StyledIsDone {...rest}><span>{text}</span></StyledIsDone>
	})
);

IsDone.defaultProps = {
	text: "",
	theme: defaultTheme
};

export default IsDone;