import React from 'react';
import { observer, inject } from 'mobx-react';  
import defaultTheme from '../../../theme';
import List from './List';

const Summary = inject('chatStore')(
	observer((props) => {
		return <List {...props} />
	})
);

Summary.defaultProps = {
	title: "Summary",
	list: ["test1", ["Lorem ipsum dolor sit amet", {value: "OK", options: {styles: {color: "green"}}}], {value: "consectetur adipiscing elit.", options: {styles: {color: "red"}}}, "Aenean sodales tellus vel euismod accumsan"],
	theme: defaultTheme
};

export default Summary;