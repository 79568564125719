import React from 'react';

import { reaction } from 'mobx';

import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import styled from 'styled-components';
import defaultTheme from '../theme';
import { weakKey } from '../utils';

import classNames from 'classnames';

import {Message as MessageStore, Widget as WidgetStore} from '../stores/chatStore';
import ChatHeader from './chatHeader';
import MainFooter from './mainFooter';
import ChatFooter from './chatFooter';
import ChatMessage from './chatMessage';

import Widget from './widgets/Widget';

const MainChat = inject('chatUiStore', 'chatStore')(
    observer(
        class MainChat extends React.Component {


            ///////
            // stepReaction = reaction(
            //     () => this.props.chatStore.stepSimulation,
            //     step => this.goToStep(step)
            // );

            // componentWillUnmout(){
            //     this.stepReaction.dispose();
            // }

            // goToStep = (step) => {
            //     if(this.props.chatStore.simulationWidgets[step]){
            //         this.renderStep(this.props.chatStore.simulationWidgets[step]);
            //     }
                
            //     this.props.chatUiStore.scrollToBottom();
            // }

            // renderStep(simulationWidget){
            //     console.log(simulationWidget, "simulationWidget");
            //     this.props.chatStore.addWidget(...simulationWidget);
            // }
            ///////

            shouldDisplayTime(index){
                const { chatStore, chatUiStore: {displayTime} } = this.props;
                if(displayTime === "onTime"){
                    return (index === 0 || moment(chatStore.messages[index].time).diff(chatStore.messages[index-1].time, 'seconds') > 10) ? true : false;                                    
                } else if(displayTime === "onChange"){
                    return (index === 0 || chatStore.messages[index].from !== chatStore.messages[index - 1].from) ? true : false;
                } else if (displayTime === "never"){
                    return false;
                }
                return true;
            }

            renderWidget(widget, index){
                return <TransitionGroup key={weakKey(widget)} >
                    <CSSTransition classNames="MessageTransition" timeout={300} appear={true}>
                        <Widget 
                            tag={`${widget.context}.${widget.name}`}
                            {...widget.params}
                            id={widget.id}
                        />
                    </CSSTransition>
                </TransitionGroup>
            }

            renderMessage(message, index) {
                const { chatStore } = this.props;

                if(message.from === "bot" || message.from === "user" || message.from === "member"){
                    return <TransitionGroup key={weakKey(message)} >
                        <CSSTransition classNames="MessageTransition" timeout={300} appear={true}>
                            <ChatMessage 
                                {...message}
                                displayTime={this.shouldDisplayTime(index)}
                                //previousType={(index > 0) ? chatStore.messages[index - 1].from : null}
                            />
                        </CSSTransition>
                    </TransitionGroup>
                }
                return null; 
            }

            render(){
                const { chatUiStore, chatStore, ...rest} = this.props;

                return (
                    <div className={classNames("ChatBot__OuterWrapper", rest.className, `ChatBot__OuterWrapper_${chatUiStore.type}`)}>

                        { chatStore.messages.length === 0 && <div className="ChatBot__Loader"></div> }

                        <div className={classNames({"ChatBot__Container": true, "open": chatUiStore.isOpen})}>
                            <div className={classNames({"ChatBot__wrapper": true, "ChatBot__wrapperWithFooter": chatUiStore.displayFooter})}>
                                {
                                    <ChatHeader></ChatHeader>
                                }
                                <div className="ChatBot__Main">
                                    <div className="container">
                                        <div 
                                            className={classNames({'ChatBot__Scroll': true})}
                                            style={{height: '100%'}}
                                        >
                                            {
                                                chatStore.messages.map((oneMessage, index) => {
                                                    if(oneMessage instanceof MessageStore){
                                                        return this.renderMessage(oneMessage, index);
                                                    }
                                                    if(oneMessage instanceof WidgetStore){
                                                        return this.renderWidget(oneMessage, index);
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>


                                <ChatFooter />
                            </div>
                            { 
                                chatUiStore.displayFooter && <MainFooter />
                            }
                        </div>
                    </div>
                )
            }
        }
    )
);


const StyledMainChat = styled(MainChat)`
  background-color: ${props => props.theme.backgroundColor};
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.mainTextColor};
  .ChatBot__Main{
    background-color: ${props => props.theme.backgroundColor};

    .container{
        width: 100%;
        max-width: none;
        padding-right: 0;
        padding-left: 0;
    }
  }
`;

StyledMainChat.defaultProps = {
    theme: defaultTheme
};

export default StyledMainChat;