import React, {Fragment} from 'react';
import styled, {keyframes, css} from 'styled-components';
import defaultTheme from '../theme';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo_bullet from '../pastille.png';

const StyledBubble = styled.div`
	position: relative;
	display: inline-block;
	text-align: left;
	overflow: hidden;
	max-width: calc(100% - 10px);
	width: ${props => props.width};

	box-shadow: ${props => {
		return (props.showBubble ? props.theme.shadow: "none");
	}};

	${props => !props.showBubble ? "background: none !important" : ""};

	p{
		padding: 8px 20px;
		max-width: 300px;
	}

	.speaker_btn{
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    width: 60px;

	    display: flex;
	    align-items: center;
	    justify-content: center;

	    span{
		    color: ${props => props.theme.inputIconColor};
		    font-size: 14px;
	    	cursor: pointer;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    background-color: ${props => props.theme.inputIconBackgroundColor};
		    border-radius: 50%;
		    height: 30px;
		    width: 30px;
	    }
	}
`;


const ballAnimation = keyframes`
 	from {
    	transform: translateY(0) scaleY(.8);
 	}
  	to {
    	transform: translateY(-10px);
  	}
`;

const ball = css`
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: rgba(191, 202, 216, .5);
	z-index: 2;
	margin-top: 4px;
	animation: ${ballAnimation} .45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
`;

const StyledLoading = styled.span`
	max-width: 100px;
	white-space: nowrap;

	&::before {
		${ball}
		margin-left: -3px;
		border: none;
		animation-delay: .15s;
	}

	& span {
		display: block;
		width: 32px;
		height: 27px;
		position: relative;

		&::before {
			${ball}
			margin-left: -16px;
		}

		&::after {
			${ball}
			margin-left: 10px;
			animation-delay: .3s;
		}
	}
`;

const StyledAvatar = styled.div`
    position: absolute;
    top: 3px;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5eaef;
`;

const StyledMessage = styled.div`
	position: relative;

	padding-left: ${props => props.withBotOffset ? "42px" : "0px"};

	img{
		max-width: 100%;
		max-height: 100%;
	}
`;

const StyledTime = styled.span`
 	display: block;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 6px;
    margin-bottom: 12px;
    text-align: center;
    border-style: solid;
    border-radius: 12px;
    padding: 3px 12px;
    margin-left: auto;
    margin-right: auto;

    background: ${props => props.theme.timeBgColor};
    border-color: ${props => props.theme.timeBorderColor};
    border-width: ${props => props.theme.timeBorderColor !== "transparent" ? "1px" : "0px"};
    color: ${props => props.theme.timeTextColor};
`;

const StyledBottomTime = styled.span`
 	display: block;
    font-size: 12px;
    line-height: 1.2;
    padding: 3px 0px;
	color: #bfcad8;

`;

const StyledMessageBox = styled.div`
	display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 10px;
    padding: 0 15px;
    z-index: 3;

	text-align: ${props => props.from === "user" ? "right" : "left"};

	${StyledBubble} {
		border-radius: ${props => props.rounded ? (props.from === "user") ? "20px 20px 4px 20px" : "20px 20px 20px 4px" : "0px"};
		background: ${props => (props.from === "user" ? props.theme.userBubbleBgColor : props.theme.botBubbleBgColor)};
		
		p {
			color: ${props => props.from === "user" ? props.theme.userBubbleTextColor : props.theme.botBubbleTextColor };
			padding: ${props => ((props.from === "bot" || props.from === "member") && props.displayPlayer && props.loading === "false") ? "8px 60px 8px 20px" : "8px 20px" };
		}
	}

	${StyledAvatar} {
	    right: ${props => props.from === "user" ? 0 : "none"};
	    left: ${props => props.from === "user" ? "none" : 0};
	}
`;

const Avatar = inject('chatUiStore')(
	observer(({from, chatUiStore}) => {
		return <>
		{
			(from === "bot" || from === "member") && chatUiStore.showBotAvatar && <StyledAvatar>
				<img src={chatUiStore.avatarURL || logo_bullet} alt="reecall avatar" />
			</StyledAvatar>
		}	
		</>
	})
);

const ChatBubble = inject('chatUiStore' ,'chatStore')(
	observer(
		class ChatBubble extends React.Component {

			state = {
				audioIndex: 0,
				audioPlaying: false
			}

			constructor(props){
				super(props);
    			this.soundRefs = [];
			}

			componentDidMount(){
				this.scrollToBottom();
			}

			componentDidUpdate(prevProps){
				if (this.props.loading !== prevProps.loading) {
					this.scrollToBottom();
				}
			}

			scrollToBottom(){
				const { chatUiStore } = this.props;
				chatUiStore.scrollToBottom();
			}

			playSentence(index){
				if(this.soundRefs[index]){
					this.soundRefs[index].play();
					this.setState({...this.state, audioPlaying: true});
				} else {
					this.setState({...this.state, audioIndex: 0, audioPlaying: false});
				}
			}

			stopAudio(){
				this.soundRefs[this.state.audioIndex].pause();
				this.soundRefs[this.state.audioIndex].currentTime = 0;
				this.setState({...this.state, audioIndex: 0, audioPlaying: false});
			}

			render() {
				const { from, message, time, rounded, displayTime, previousType, chatUiStore, mp3s, showBubble, showAvatar, loading } = this.props;

				return (
					<StyledMessageBox className={classNames("ChatBot__Message", `ChatBot__Message_${from}`)} from={from} displayPlayer={chatUiStore.showSpeaker} rounded={rounded} loading={loading.toString()} >
						<div className="content">
							{
								displayTime && <StyledTime className={"ChatBot__Message-time"} >
									{
										moment(time).calendar(null,{
										    lastDay : '[Yesterday]',
										    sameDay : '[Today]',
										    nextDay : '[Tomorrow]',
										    lastWeek : '[last] dddd',
										    nextWeek : 'dddd',
										    sameElse : 'L'
										})
									}</StyledTime>
							}
							<StyledMessage withBotOffset={(from === "bot" || from === "member") && chatUiStore.showBotAvatar && showAvatar}>
									
									{ showAvatar && <Avatar from={from}/> }

									<TransitionGroup>
										<CSSTransition classNames="BubbleTransition" timeout={500} appear={true}>
											<div style={{width: "100%"}}>
												<StyledBubble showBubble={showBubble} width="auto">
													{ 	
														!loading && <Fragment>
															{ message && <p>
																{message}
																{
																	(chatUiStore.showSpeaker && from === "bot" && mp3s) && <span className="speaker_btn" onClick={() => (this.state.audioPlaying) ? this.stopAudio() : this.playSentence(0)}>
																		{
																			mp3s.map((mp3, index) => {
																				return <audio 
																					key={`audio-${mp3}`}
																					src={mp3}
																					ref={(ref) => this.soundRefs[index] = ref}
																					onEnded={() => {
																						if(this.state.audioPlaying){
																							this.setState({audioIndex: this.state.audioIndex + 1}, () => {
																								this.playSentence(this.state.audioIndex);
																							})
																						}
																					}}
																				/>
																			})
																		}
																		<span><FontAwesomeIcon icon={(this.state.audioPlaying) ? "stop" : "play"}/></span>
																	</span>
																}
															</p> }
														</Fragment>
													}
													{
														loading && <p>
															<StyledLoading><span></span></StyledLoading>
														</p>
													}
												</StyledBubble>	
											</div>
										</CSSTransition>
									</TransitionGroup>

									{
										(showBubble && chatUiStore.displayTimeUnderMessage) && <TransitionGroup>
		                                	<CSSTransition classNames="MessageTimeUnderTransition" timeout={800} appear={true}>
												<StyledBottomTime className={"ChatBot__Message-time-under"} >{moment(time).format("LT")}</StyledBottomTime>
											</CSSTransition>
										</TransitionGroup>
									}
								
							</StyledMessage>
						</div>
					</StyledMessageBox>
				);
			}
		}
	)
);

ChatBubble.defaultProps = {
	from: "user",
	message: "",
	time: null,
	displayTime: true,
	theme: defaultTheme,
	rounded: true,
	showBubble: true,
	showAvatar: true,
	loading: true
};
export default ChatBubble;

export {StyledMessageBox, StyledMessage, StyledBubble, Avatar};
