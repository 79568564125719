import React from 'react';

const LogoReecall = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.32 18" className={props.className}>
		<g>
			<ellipse cx="79.23" cy="15.2" rx="1.7" ry="1.72" fill={props.fill}/>
			<path fill={props.fill} d="M71.12.76H74.4V16.92H71.12Zm-6.79,0h3.28V16.92H64.33Zm-3.5,5.09V16.92H57.56v-1a4.09,4.09,0,0,1-3.26,1.35c-2.86,0-5.23-2.54-5.23-5.84s2.35-5.84,5.23-5.84c2.59,0,3.68.32,6.08.32Zm-3.28,5.53a2.6,2.6,0,1,0-5.2,0,2.6,2.6,0,1,0,5.2,0Zm-20.85,0a5.7,5.7,0,0,1,5.79-5.84,5.61,5.61,0,0,1,4.94,2.84l-2.86,1.67a2.26,2.26,0,0,0-2.12-1.24A2.42,2.42,0,0,0,40,11.39,2.42,2.42,0,0,0,42.45,14a2.24,2.24,0,0,0,2.12-1.24l2.86,1.66a5.57,5.57,0,0,1-4.94,2.86A5.7,5.7,0,0,1,36.7,11.39Zm-7.56,2.87a2.84,2.84,0,0,0,2.1-.84l2.62,1.53a5.52,5.52,0,0,1-4.77,2.28A5.68,5.68,0,0,1,23,11.39a5.64,5.64,0,0,1,5.81-5.84,5.56,5.56,0,0,1,5.55,5.84,6.49,6.49,0,0,1-.13,1.33H26.51A2.48,2.48,0,0,0,29.14,14.26Zm2-4a2.25,2.25,0,0,0-2.32-1.75,2.25,2.25,0,0,0-2.38,1.75Zm-15.67,4a2.84,2.84,0,0,0,2.1-.84l2.62,1.53a5.52,5.52,0,0,1-4.77,2.28,5.68,5.68,0,0,1-6.05-5.84,5.64,5.64,0,0,1,5.81-5.84,5.56,5.56,0,0,1,5.55,5.84,6.49,6.49,0,0,1-.13,1.33H12.85A2.48,2.48,0,0,0,15.47,14.26Zm2-4a2.25,2.25,0,0,0-2.32-1.75,2.25,2.25,0,0,0-2.38,1.75ZM7.63,5.63V9.4c-1.36-.22-3.28.33-3.28,2.52v5H1.07V5.86H2.25C4.35,5.86,4.75,5.63,7.63,5.63Z"/>
		</g>
	</svg>
);

LogoReecall.defaultProps = {
	className: "",
	fill: "#bfcad8"
}

export default LogoReecall;