import React, {useState, useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {StyledHint, StyledList} from './QuickReplies';
import RcButton from '../../button';
var uniqid = require('uniqid');

const StyledQuickRepliesMultiples = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	padding: 10px 0 10px 10px;
`;

const List = styled(StyledList)`
	
`;


const StyledChoice = styled(StyledHint)`
	
`;

const QuickRepliesMultiples = inject('chatStore', 'chatUiStore')(
	observer(({list, onSubmit, id, ...rest}) => {
		const { t } = useTranslation();
  		const listId = useRef(uniqid());
		const [seletedIndexes, setSeletedIndexes] = useState([]);
		return <StyledQuickRepliesMultiples>
			<List {...rest} >
				{
					list.map((choice, index) => {
						return (
							<StyledChoice 
								value={index}
								onClick={(e) => {
									console.log(seletedIndexes.find(e => e === index), "test");
									if(typeof seletedIndexes.find(e => e === index) === "undefined"){
										setSeletedIndexes([...seletedIndexes, index]);
									} else {
										setSeletedIndexes([...seletedIndexes.filter(e => e !== index)])
									}
								}}
								actif={seletedIndexes.findIndex(e => e === index) !== -1}
								key={`choicelist-${listId.current}-hint-${index}`}
							>
								{ choice }
							</StyledChoice>
						)
					})
				}
			</List>
			<RcButton 
				onClick={(e) => {
					console.log(seletedIndexes, "seletedIndexes");
					console.log(list.filter((elt, index) => seletedIndexes.includes(index) ));
				}}
			>{t('submit')}</RcButton>
		</StyledQuickRepliesMultiples>
	})
);

QuickRepliesMultiples.defaultProps = {
	list: ['sed tempor lectus', 'Lorem ipsum', 'dolor sit amet', 'consectetur adipiscing elit', 'Integer sollicitudin facilisis accumsan'],
	onSubmit: (e) => { console.log(e); }
};

export default QuickRepliesMultiples;