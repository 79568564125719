import React from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import styled from 'styled-components';
import defaultTheme from '../theme';
import { withTranslation } from 'react-i18next';

const ChatFooter = inject('chatStore', 'chatUiStore')(
	observer(
		class ChatFooter extends React.Component {

			state = {
				inputVal: ""
			}

			sendMessage() {
				const { chatStore, chatUiStore } = this.props;
				let message = this.state.inputVal;
				this.setState({inputVal: ""});
				if(message !== ""){
					chatStore.sendMessage({message}, (msg) => {
						chatUiStore.scrollToBottom();
					});

                	chatStore.stepSimulation = chatStore.stepSimulation + 1;
				}
			}

			render() {
				const { t } = this.props;
				return (
					<div className={classNames("ChatBot__Footer", this.props.className)}>
						<div className="container">
				            <form onSubmit={(e) => { e.preventDefault(); this.sendMessage(); }}>
				                <input 
				                	type="text"
				                	className="form-control"
				                	id="yourmessage"
				                	placeholder={`${t("Type message")}...`}
				                	autoComplete="off"
				                	value={this.state.inputVal}
				                	onChange={(e) => {
				                		this.props.chatStore.typing();
				                		this.setState({inputVal: e.target.value})}
			                		}
			                	/>
				                <button type="submit" id="sendmessage" className={classNames({"btn prepend" : true, "round": this.props.theme.inputIconBgColor !== "transparent"})}>
				                	<svg xmlns="http://www.w3.org/2000/svg"
				                        width="24" height="24" viewBox="0 0 24 24" className="eva eva-paper-plane">
				                        <g data-name="Layer 2">
				                            <g data-name="paper-plane">
				                                <rect width="24" height="24" opacity="0"></rect>
				                                <path
				                                    d="M21 4a1.31 1.31 0 0 0-.06-.27v-.09a1 1 0 0 0-.2-.3 1 1 0 0 0-.29-.19h-.09a.86.86 0 0 0-.31-.15H20a1 1 0 0 0-.3 0l-18 6a1 1 0 0 0 0 1.9l8.53 2.84 2.84 8.53a1 1 0 0 0 1.9 0l6-18A1 1 0 0 0 21 4zm-4.7 2.29l-5.57 5.57L5.16 10zM14 18.84l-1.86-5.57 5.57-5.57z">
				                                </path>
				                            </g>
				                        </g>
				                    </svg>
				                </button>
				            </form>
			            </div>
					</div>
				);
			}
		}
	)
);


const ChatFooterStyled = styled(ChatFooter)`
	background-color: ${props => props.theme.backgroundColor};

	input {
		background-color: ${props => props.theme.inputBgColor};
		color: ${props => props.theme.inputTextColor};
		border-radius: ${props => props.rounded ? '28px' : '0'};
		box-shadow: ${props => props.theme.inputShadow};

		&:focus {
			background: ${props => props.theme.inputBgColor};
			color: ${props => props.theme.inputTextColor};
			box-shadow: ${props => props.theme.inputShadow};
		}

		&::placeholder {
			color: ${props => props.theme.inputTextColor}
		}
	}
	button {
		background-color: ${props => props.theme.inputIconBgColor};
	}
	svg {
		fill: ${props => props.theme.inputIconColor};
	}
`;

ChatFooterStyled.defaultProps = {
	rounded: true,
	theme: defaultTheme
};

export default withTranslation()(ChatFooterStyled);
