import { decorate, observable, action } from 'mobx';
import moment from 'moment';
import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons';
import i18next from 'i18next';
import DefaultConnector from '../connectors/DefaultConnector';
var uniqid = require('uniqid');

class Message {
    constructor({id, from = "bot", message = "", time = moment(), loading = true}) {
        message = (typeof message === "string") ? message : ( message.message || message.msg );

        this.id = id || `message-${uniqid()}`;
        this.from = from;
        this.message = message;
        this.time = time;
        this.loading = loading;
    }
}
decorate(
  Message, {
    from: observable,
    message: observable,
    time: observable,
    loading: observable
});
export {Message};

class Widget {
    constructor({id, name, context = "Generics", params = {}}) {
        this.id = id || `message-${uniqid()}`;
        this.name = name;
        this.context = context;
        this.params = params;
    }
}
decorate(
  Widget, {
    name: observable,
    context: observable,
    params: observable
});
export {Widget};

let memberTypingInterval;

class chatStore {
    messages = [];
    isMemberTyping = false;
    chatUiStore = null;
    theme = {};
    token = null;
    userId = null;

    firstConnection = true;

    stepSimulation = -1;
    simulationWidgets = [];

    constructor(token, userID, contactInfos, chatUiStore) {
        this.token = token;
        this.userId = userID;
        this.chatUiStore = chatUiStore;
        this.connector = new DefaultConnector();
    }

    setupEvents(){

        this.connector.connect(this.userId, this.token).then(datas => {
            this.handleMessage(datas);
        }).catch(err => console.log(err, "error while connecting to generic connector"));

        // this.ws.registerEventListener('typing', () => {
        //     if(this.isMemberTyping === false){
        //         this.addMessage(new Message({message: "", from: "member", id: "typing_message_member", loading: true}));
        //     }
        //     this.resetIsMemberTyping(true);
        // });

        // this.ws.registerEventListener('isDone', () => {
        //     this.addWidget("isDone", "Generics", {text: `${i18next.t('Thanks for chating')} 😍`});
        //     this.ws.disconnect();
        // });

        // this.ws.registerEventListener('message', (datas) => {
        //     this.handleMessage(datas);
        // });

        // this.ws.registerEventListener('conversation', (msgs) => {
        //     if(this.firstConnection){
        //         this.firstConnection = false;
        //         this.messages = [];
        //         msgs.map(msg => {
        //             if( typeof msg.message !== "undefined"){

        //                 let message = (typeof msg.message === "string") ? msg.message : ( msg.message.message || msg.message.msg );
        //                 this.addMessage(new Message({from: msg.from, message: message, time: msg.time, loading: false}));
        //                 return message;
        //             }
        //             return msg;
        //         });
        //     }
        // });
    }

    sendMessage(message, cb = () => {}){
        let id = this.addMessage(new Message({message: message, from: "user"}));

        this.connector.say(this.userId, message.message.toString().trim()).then(datas => {
            let currentMessage = this.messages.find(msg => msg.id === id);
            currentMessage.loading = false;
            this.handleMessage(datas);
            cb();
        });
    }

    addMessage(message){
        this.messages.push(message);
        return message.id;
    }

    removeMessageById(id){
        const filteredMessage = this.messages.filter(item => item.id !== id);
        this.messages = filteredMessage;
    }

    replaceMessageById(id, message){
        this.messages[this.messages.findIndex(msg => msg.id === id)] = message;
    }

    resetIsMemberTyping(isTyping = false){
        this.isMemberTyping = isTyping;
        this.chatUiStore.scrollToBottom();
        clearInterval(memberTypingInterval);
        memberTypingInterval = setInterval(function(){
            if(this.isMemberTyping){
                this.isMemberTyping = false;
                this.removeMessageById("typing_message_member");
                this.chatUiStore.scrollToBottom();
            }
        }.bind(this), 5000);
    }

    handleMessage(datas){

        if(datas.widgets){
            const widgetsOut = datas.widgets.filter(widget => widget.direction === "out");
            widgetsOut.map((widget) => {
                return this.addWidget(widget.name, "Generics", widget.params);
            });
        }

        if(this.isMemberTyping){
            this.replaceMessageById('typing_message_member', new Message({message: datas.message, from: "member", loading: false}));
        } else {
            this.addMessage(new Message({message: datas.message, from: "member", loading: false}));
        }
        this.isMemberTyping = false;

        if(datas.widgets){
            const widgetsIn = datas.widgets.filter(widget => widget.direction === "in");
            widgetsIn.map((widget) => {
                return this.addWidget(widget.name, "Generics", widget.params);
            });
        }

        if(datas.isDone === true) {
            if(datas.variables && Object.entries(datas.variables).length > 0){
                let listVariables = [];

                for (let [, variable] of Object.entries(datas.variables)) {
                    let valueOptions = {
                        "styles": {
                            "color": this.theme.mainColor
                        }
                    };
                    let displayKeyOptions = {
                        "styles": {
                            "font-weight": 600
                        },
                        icon: 'circle'
                    };
                    let displayKey = {value: variable.displayKey, options: {}};
                    let value = {value: null, options: {}};

                    if(variable.guess){
                        switch(variable.guess.type){
                            case "address":
                                displayKeyOptions.icon = "map-marker-alt";
                                value.value = variable.guess.datas.formatted_address;
                                break;
                            case "choice":
                                displayKeyOptions.icon = "comment";
                                value.value = variable.guess.datas.result.reduce((prev, curr) => [prev, ` or `, curr]);
                                break;
                            case "time":
                                
                                displayKeyOptions.icon = "clock";
                                if (variable.guess.datas.from && variable.guess.datas.to) {
                                    let isSameDay = moment(variable.guess.datas.from).isSame(moment(variable.guess.datas.to), 'date');
                                    if(isSameDay){
                                        value.value = i18next.t(`{{day}} between {{from}} and {{to}}`, { day: moment(variable.guess.datas.from).locale(`tiny_${moment.locale() || 'fr'}`).calendar(), from: moment(variable.guess.datas.from).format("HH:mm"), to: moment(variable.guess.datas.to).format("HH:mm")});
                                    } else {
                                        value.value = moment(variable.guess.datas.from).calendar() + " → " + moment(variable.guess.datas.to).calendar();
                                    }
                                } else if(variable.guess.datas.date || variable.guess.datas.from){
                                    value.value = moment(variable.guess.datas.date).calendar() || moment(variable.guess.datas.from).calendar();
                                }

                                break;
                            case "person":
                                displayKeyOptions.icon = "user";
                                value.value = variable.guess.datas.result.reduce((prev, curr) => [prev, ` or `, curr]);
                                break;
                            default:
                        }
                    } else {
                        value.value = variable.value;
                    }
                
                    displayKey.options = displayKeyOptions;
                    value.options = valueOptions;

                    listVariables.push([displayKey, value]);
                }

                this.addWidget("summary", "Generics", {list: listVariables});
            }
        }

        this.chatUiStore.scrollToBottom();
    }

    addWidget(name, context, params){
        this.messages.push(new Widget({
            name: name,
            context: context,
            params: params
        }));
    }

    typing(){
        //this.ws.sendTyping();
    }
};

decorate(
  chatStore, {
    messages: observable,
    theme: observable,
    token: observable,
    userId: observable,
    connect: action,
    removeMessageById: action,
    addMessage: action,
    sendMessage: action,
    typing: action,
    addWidget: action,

    stepSimulation: observable,
    simulationWidgets: observable,
});

export default chatStore;