import React from 'react';
import styled from 'styled-components'; 

export const StyledButton = styled.button`
    position: relative;
    
	border: 0px solid transparent;
	border-radius: 4px;
	padding: 6px 12px;
	margin: 4px;

	font-size: 16px;
	line-height: 1.7;
	font-weight: 600;

    background: ${props => props.theme.hintBgColor};
	color: #fff;
    box-shadow: ${props => props.theme.shadow};

    user-select: none;
    cursor: pointer;
  	outline: none !important;

  	text-transform : uppercase;

	&:hover{
		background: ${props => props.theme.hintTextColor};
		color: ${props => props.theme.hintBorderColor};
	}
`;


const Button = ({children, onClick, ...props}) => {

	return <StyledButton
		{...props}
		className="rc-button"
		onClick={onClick}
	>
		{ children }
	</StyledButton>
};

Button.defaultProps = {
	onClick: (e) => {console.log(e, "e")}
};

export default Button;