import React, {useState} from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';  
import defaultTheme from '../../../theme';
import  classNames from 'classnames';

export const StyledDownload = styled.div`
    --duration: 4000;
    --success: #11cb72;
    --grey: #8292a5;
    --shadow-dark: rgba(18, 22, 33, .85);
    --progressScale: ${props => props.scale};
	--shadow: ${props => props.theme.shadow};

    --bg-color : ${props => props.backgroundColor ? props.backgroundColor : props.theme.mainColor};
    --text-color : ${props => props.textColor};

	--arrow-color: ${props => props.arrowColor ? props.arrowColor : props.theme.mainColor};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


const Download = inject('chatStore')(
	observer((props) => {
		const [state, setState] = useState("");
		const [scale, setScale] = useState(0);

		return <StyledDownload scale={scale} {...props}>
			<a href={props.href} className={classNames({"dl-button" : true, [`${state}`] : true})} onClick={() => { setState("active"); setScale(1)}}>
				<div>
					<div className="icon">
						<div>
							<svg className="arrow" viewBox="0 0 20 18" fill="currentColor">
								<polygon points="8 0 12 0 12 9 15 9 10 14 5 9 8 9"></polygon>
							</svg>
							<svg className="shape" viewBox="0 0 20 18" fill="currentColor">
								<path d="M4.82668561,0 L15.1733144,0 C16.0590479,0 16.8392841,0.582583769 17.0909106,1.43182334 L19.7391982,10.369794 C19.9108349,10.9490677 19.9490212,11.5596963 19.8508905,12.1558403 L19.1646343,16.3248465 C19.0055906,17.2910371 18.1703851,18 17.191192,18 L2.80880804,18 C1.82961488,18 0.994409401,17.2910371 0.835365676,16.3248465 L0.149109507,12.1558403 C0.0509788145,11.5596963 0.0891651114,10.9490677 0.260801785,10.369794 L2.90908938,1.43182334 C3.16071592,0.582583769 3.94095214,0 4.82668561,0 Z"></path>
							</svg>
						</div>
						<span></span>
					</div>
					<div className="label">
						<div className="default show">Download</div>
						<div className="state">
							<span>Done</span>
						</div>
					</div>
					<div className="progress"></div>
				</div>
			</a>
		</StyledDownload>
	})
);

Download.defaultProps = {
	textColor: "#fff",
	href: null,
	theme: defaultTheme
};

export default Download;