import React, {useState, useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import { observer, inject } from 'mobx-react';
import defaultTheme from '../../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/pro-light-svg-icons';
var uniqid = require('uniqid');

const StyledHint = styled.li`

	border-width: 1px;
	border-style: solid;
	border-radius: 4px;
	padding: 6px 12px;
	min-width: 50px;
	max-width: 370px;
	box-shadow: ${props => props.theme.shadow};
	text-align: center;
	cursor: pointer;
	user-select: none;
	transition: transform 0.1s;

	${props => !props.isBool && css`
		background: ${props => props.actif ? props.theme.hintTextColor : props.theme.hintBgColor};
		color: ${props => props.actif ? props.theme.hintBorderColor : props.theme.hintTextColor};
		border-color: ${props => props.theme.hintBorderColor};
		//transform: ${props => !props.actif ? 'scale(1)' : 'scale(0.96)'};

		&:hover{
			background: ${props => props.theme.hintTextColor};
			color: ${props => props.theme.hintBorderColor};
			/*transform: scale(0.96);*/
		}
	`}

	${props => props.isBool && css`
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		border-radius: 4px;
		padding: 12px 20px;
		margin-left: 6px;
		min-width: 72px;

		svg{
			font-size: 22px;
		}
	`}

	${props => props.isBool && props.value === true && css`
		// color: #11cb72;
		// border-color: #11cb72;
		color: #fff;
		background-color: #11cb72;
	`}

	${props => props.isBool && props.value === false && css`
		// color: #ff5451;
		// border-color: #ff5451;
		color: #fff;
		background-color: #ff5451;
	`}

`;

const StyledList = styled.ul`
	display: flex;
	flex-direction: ${props => props.direction};
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	flex-wrap: wrap;
	padding: 4px 0;
	margin-left: auto;
	margin-right: auto;


	${StyledHint}{

		${props => !props.isBool && css`
			width: ${props => props.direction === "column" ? "100%": "auto"};
			margin: ${props => props.fullWidth && props.direction === "column" ? "4px 0": "4px"};
			flex-grow: ${props => ((props.fullWidth && props.direction === "row") || props.insideMessage) ? "1": "initial"};

			border-color: ${props => (props.type === "user" ? props.theme.userBubbleBgColor : props.theme.botBubbleBgColor)};
		`}
	}
`;


const QuickReplies = inject('chatStore', 'chatUiStore')(
	observer(({list, onClick, id, ...rest}) => {
		const [actif, setActif] = useState(null);
  		const listRef = useRef(null);
  		const listId = useRef(uniqid());

  		useEffect(() => {
  			if(listRef.current){
		        const rect = listRef.current.getBoundingClientRect();
		        rest.chatUiStore.bottomHeightForHints = rect.height;
		        rest.chatUiStore.scrollToBottom();
  			}
	    }, [listRef, rest.chatUiStore, rest.chatUiStore.bottomHeightForHints])

		let isBool = !list.filter((hint) => typeof hint !== "boolean" ).length;

		return actif === null ? <StyledList isBool={isBool} {...rest} >
			{
				list.map((hint, index) => {
					return (
						<StyledHint 
							value={hint} 
							isBool={isBool}
							onClick={(e) => {
								rest.chatStore.removeMessageById(id);
								rest.chatStore.sendMessage({message: hint});
								setActif(index);
							}}
							actif={index === actif}
							key={`hintslist-${listId.current}-hint-${index}`}
						>
							{ isBool && hint === true && <FontAwesomeIcon icon={faCheck} /> }
							{ isBool && hint === false && <FontAwesomeIcon icon={faTimes} /> }
							{ hint }
						</StyledHint>
					)
				})
			}
		</StyledList> : null
	})
);

QuickReplies.defaultProps = {
	list: [],
	theme: defaultTheme
};

export {StyledHint, StyledList};
export default QuickReplies;