import React from 'react';
import { observer, inject } from 'mobx-react';
import LogoReecall from "./icons/LogoReecall";

const MainFooter = inject('chatUiStore')(
	observer(
		class MainFooter extends React.Component {
			render() {
				return (
					<div className="RC_footer">
						<div className="container">
							<a 
								href="http://www.reecall.co"
								style={{ display: "flex", alignItems: "flex-end"}}
								target="_blank"
								rel="noopener noreferrer"
							>
								Powered by <LogoReecall className={"RC_footer__logo"} />
							</a>
						</div>
					</div>
				);
			}
		}
	)
);
MainFooter.defaultProps = {};
export default MainFooter;
