import React from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';  
import defaultTheme from '../../../theme';

export const StyledButton = styled.a`
	--shadow: ${props => props.theme.shadow};

    --bg-color : ${props => props.backgroundColor};
    --text-color : ${props => props.textColor};

    position: relative;
    background: var(--bg-color);
    color: var(--text-color) !important;
    border-radius: 5px;
    overflow: hidden;
    padding: 16px 24px;
    margin: 10px;
    box-shadow: var(--shadow);

    user-select: none;

    cursor: pointer;
`;


const Button = inject('chatStore')(
	observer((props) => {

		return <StyledButton
			{...props}
			href={props.href}
			className="rc-button"
			onClick={props.onClick}
		>
				{
					props.children
				}
		</StyledButton>
	})
);

Button.defaultProps = {
	backgroundColor: "#FFF",
	textColor: "#8292a5",
	href: null,
	onClick: (e) => {console.log(e, "e")},
	theme: defaultTheme
};

export default Button;