import moment from 'moment';

const fr = {
    months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact : true,
    weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY à HH[h]mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        ss : '%d secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    }
}

moment.updateLocale('fr', fr);
moment.locale('small', {...fr, relativeTime : {
    future : 'dans %s',
    past : 'il y a %s',
    s : '1 s',
    ss : '%d s',
    m : '1 min',
    mm : '%d mins',
    h : '1 h',
    hh : '%d h',
    d : '1 j',
    dd : '%d j',
    M : '1 mois',
    MM : '%d mois',
    y : '1 a',
    yy : '%d a'
}});

moment.locale('tiny_fr', {...fr, 
    calendar: {
        lastDay : '[Hier]',
        sameDay : '[Aujourd\'hui]',
        nextDay : '[Demain]',
        lastWeek : 'dddd [dernier]',
        nextWeek : 'dddd',
        sameElse : 'L'
    }
});

moment.locale('fr');