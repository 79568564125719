import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import defaultTheme from '../theme';

class ChatHeader extends React.Component {
	render() {
		return (
			<div className={classNames("ChatBot__Header", this.props.className)}>
				<div className="container">
					{this.props.children}
			    </div>
			</div>
		);
	}
}
ChatHeader.defaultProps = {
	theme: defaultTheme
};

const ChatHeaderStyled = styled(ChatHeader)`
	background-color: ${props => props.theme.headerBgColor};
	color: ${props => props.theme.headerTextColor};
`;

export default ChatHeaderStyled;
