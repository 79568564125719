import React, {useState} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';  
import defaultTheme from '../../../theme';
import classNames from 'classnames';

const StyledTimeSelector = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 370px;
    color: #bfcad8;
    font-size: 16px;
    line-height: 24px;

    .separator {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 64px;
        color: #bfcad8;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        text-align: center;

        span {
            position: absolute;
            top: 50%; 
            left: 50%; 
            transform: translate(-50%, -50%);
            background-color: #FFFF;
            padding: 0px 8px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 50%; 
            left: 50%; 
            transform: translate(-50%, -50%);
            height: 1px;
            width: 100%;

            background-image: linear-gradient(to right, #e5eaef 33%, rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: 15px 1px;
            background-repeat: repeat-x;
        }
    }
`;

const StyledTimeListSelector = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

const StyledTimeItemSelector = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex-shrink: 1;

    height: 50px;
    min-width: 84px;
    margin: 4px;

    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 8px 0 rgba(137,137,137,0.1);

    color: #8292a5;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;

    cursor: pointer;
    box-sizing: border-box;

    &:hover {
        border: 1px solid ${props => props.theme.mainColor};
        background-color: ${props => props.theme.mainLightenColor};
        box-shadow: 0 5px 8px 0 #f9f9fa;
        color: ${props => props.theme.mainColor};
    }

    &.active{
        border: 1px solid ${props => props.theme.mainColor};
        background-color: ${props => props.theme.mainLightenColor};
        box-shadow: 0 5px 8px 0 #f9f9fa;
        color: ${props => props.theme.mainColor};
    }
`;

const TimeSelector = inject('chatStore')(
    observer(({timeList, onClick, ...rest}) => {
        const [activeTime, setActiveTime] = useState(null);

        const listItemAM = timeList.filter(function(time){
            const formattedTime = moment(time).format("a");
            return (formattedTime === "am") ? time : null;
        });

        const listItemPM = timeList.filter(function(time){
            const formattedTime = moment(time).format("a");
            return (formattedTime === "pm") ? time : null;
        });

        const createDateList = (list, type) => {
           return list.map(function(time, index){
                let formattedTime = moment(time).format("hh:mm a");
                return <StyledTimeItemSelector 
                    className={classNames({active : time === activeTime})}
                    onClick={(e) => {
                        setActiveTime(time);
                        onClick(time);
                    }}
                    key={`TimeSelector-items-${type}-${index}`}
                >
                    { formattedTime }
                </StyledTimeItemSelector>
            })
        };

        return <StyledTimeSelector {...rest}>
            <StyledTimeListSelector>{createDateList(listItemAM, 'AM')}</StyledTimeListSelector>
            <div className="separator">
                <span>12:00</span>
            </div>
            <StyledTimeListSelector>{createDateList(listItemPM, 'PM')}</StyledTimeListSelector>
        </StyledTimeSelector>
    })
);

TimeSelector.defaultProps = {
    timeList: ['2019-04-17T09:00:00', '2019-04-17T09:30:00', '2019-04-17T10:00:00', '2019-04-17T10:30:00', '2019-04-17T11:00:00', '2019-04-17T12:00:00', '2019-04-17T14:00:00', '2019-04-17T14:30:00', '2019-04-17T14:45:00', '2019-04-17T16:00:00', '2019-04-17T17:00:00', '2019-04-17T18:00:00'],
    onClick: (time) => { alert(time); },
    theme: defaultTheme
};

export default TimeSelector;