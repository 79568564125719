import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons';

const StyledGuess = styled.div`
	padding: 8px 20px;
	background-color: #fff;
	p{
   		font-size: 16px;
		line-height: 1.7;
	}

	.guess-value{
	    display: flex;
	    align-items: center;
   		font-weight: bold;

   		svg{
    		margin-top: -4px;
    		margin-right: 8px;
			color: ${props => props.theme.mainColor};
   		}
	}
`;

const StyledDateVisualizer = styled(StyledGuess)`
`;

const DateVisualizer = ({timestamp, format}) => {
	const { t } = useTranslation();
	return <StyledDateVisualizer>
		<p className="guess-title">{`${t('I noted')} :`}</p>
		<p className="guess-value">
			<FontAwesomeIcon icon={format === "LT" ? faClock : faCalendarAlt} />
			{
				moment(new Date(timestamp * 1000)).format(format)
			}
		</p>
	</StyledDateVisualizer>
}

DateVisualizer.defaultProps = {
	timestamp: 1586159282,
	format: 'L LT',
};

export default DateVisualizer;