import React from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';  
import defaultTheme from '../../../theme';
import Download from './Download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const StyledDownloadBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: ${props => props.textColor};
	background: ${props => props.backgroundColor ? props.backgroundColor : props.theme.mainColor};

	& > div{	
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px 12px;

		.icon-wrapper{
		    display: flex;
		    align-items: center;
		    justify-content: center;
			font-size: 40px;
			background-color: rgba(255, 255, 255, 0.2);
			padding: 10px;
			height: 100px;
			width: 100px;
			border-radius: 50%;
			margin-bottom: 12px;
		}

		.title{
			font-size: 22px;
			line-height: 26px;
			margin-bottom: 12px;
			text-align: center;
		}

		.content{
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			font-weight: normal;
		}
	}

	.btn-wrapper{
		padding: 20px;
		background-color: #fff;
		width: 100%;
	}


`;


const DownloadBox = inject('chatStore')(
	observer(({iconName, title, content, href, ...rest}) => {
		return <StyledDownloadBox {...rest}>
			<div>
				{ iconName && <div className="icon-wrapper">
					<FontAwesomeIcon icon={iconName} />
				</div>
				}
				{ title && <span className="title" >{title}</span> }
				{ content && <div className="content">{content}</div> }
			</div>
			<div className="btn-wrapper">
				<Download href={href} backgroundColor={rest.backgroundColor} textColor={rest.textColor} arrowColor={rest.arrowColor}/>
			</div>
		</StyledDownloadBox>
	})
);

DownloadBox.defaultProps = {
	textColor: "#fff",
	iconName: "",
	title: null,
	content: null,
	href: null,
	theme: defaultTheme
};

export default DownloadBox;