import React, {useState} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';  
import defaultTheme from '../../../theme';
import classNames from 'classnames';

const StyledDateSelector = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: ${props => props.bottom ? "center" : "flex-end"};
    flex-wrap: wrap;
    width: 100%;
    max-width: 370px;
    list-style-type: none;
    padding: 0;
`;

const StyledDateItemSelector = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /*flex: 0 1 84px;*/
    flex-shrink: 1;
    min-height: 84px;
    min-width: 84px;
    margin: 4px;

    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 8px 0 rgba(137,137,137,0.1);

    color: #8292a5;
    font-size: 14px;
    line-height: 24px;
    text-align: center;

    cursor: pointer;
    box-sizing: border-box;

    & > span{
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        text-align: center;
        color: #bfcad8;
    }

    &:hover, &.active{
        border: 1px solid ${props => props.theme.mainColor};
        background-color: ${props => props.theme.mainLightenColor};
        box-shadow: 0 5px 8px 0 #f9f9fa;
        color: ${props => props.theme.mainColor};

        & > span{
            color: ${props => props.theme.mainColor};
        }
    }
`;

const DateSelector = inject('chatStore')(
    observer(({onClick, dateList, ...rest}) => {

        const [activeDate, setActiveDate] = useState(null);

        return <StyledDateSelector {...rest}>
        {
            dateList.map(function(timestamp, index){
                return <StyledDateItemSelector 
                    className={classNames({active : index === activeDate})}
                    onClick={(e) => {
                        setActiveDate(index);
                        onClick(timestamp);
                    }}
                    key={`DateSelector-items-${index}`}
                >
                    <span>{moment(timestamp).format('ddd.')}</span>
                    {moment(timestamp).format('DD MMM.')}
                </StyledDateItemSelector>
          })
        }
        </StyledDateSelector>
    })
);

DateSelector.defaultProps = {
    bottom: false,
    dateList: ["2019-04-17", "2019-04-18", "2019-04-19", "2019-04-20", "2019-04-21", "2019-04-22", "2019-04-23", "2019-04-24", "2019-04-25"],
    onClick: (timestamp) => { console.log(timestamp, "Date Selected") },
    theme: defaultTheme
};

export default DateSelector;