import React from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';  
import defaultTheme from '../../../theme';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

export const StyledAddress = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 230px;

	color: ${props => props.theme.userBubbleTextColor};
	box-shadow: ${props => props.theme.shadow};

	overflow: hidden;

	.Rc_Address__map{
		position: relative;
		witdh: 100%;
		height: 100px;
		overflow: hidden;
		min-width: 300px;
	}
	
	.Rc_Address__bottom{
		padding: 12px;
		background: ${props => props.theme.userBubbleBgColor};
		line-height: normal;
	}

`;

const Address = inject('chatStore', 'chatUiStore')(
	observer((props) => {
		
		const mapOptions = {
			initialCenter: {...props.coordinates},
    	zoom: 13,
			disableDefaultUI: true,
			google: props.google,
			draggable: false,
			zoomControl: false,
			scrollwheel: false,
			disableDoubleClickZoom: true,
			styles: mapStyles,
      onReady: () => { 
        props.chatUiStore.scrollToBottom();
      }
		}

		return <StyledAddress {...props}>
			<div className='Rc_Address__map'>	
 				<Map {...mapOptions} >
					{ 
						<Marker position={{...props.coordinates}} />
					}
				</Map>
			</div>
			<div className='Rc_Address__bottom'>
				{props.address}
			</div>
		</StyledAddress>
	})
);

Address.defaultProps = {
	address: "",
	coordinates: {lat: null, lng: null},
	theme: defaultTheme
};

export default GoogleApiWrapper({
 apiKey: ('AIzaSyDn6zs6iEhUSRZrjyQ7gcni_B0oZd8Nk4s')
})(Address);


const mapStyles = [
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
]