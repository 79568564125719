import { observable, action } from 'mobx';

const chatUiStore = observable({
    isOpen: true,
    displayFooter: true,
    showBotAvatar: true,
    bottomHeightForHints: 0,
    type: "rounded",
    showSpeaker: false,
    displayTime: "never",
    displayTimeUnderMessage: true,
    avatarURL: null,
    scrollToBottom: () => {
		document.querySelector(".ChatBot__Scroll").scrollTop = document.querySelector(".ChatBot__Scroll").scrollHeight;    
    }
}, {
    scrollToBottom: action
});

export default chatUiStore;